
import Vue from "vue";

export default Vue.extend({
  name: "Dashboard",

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    series: [
      {
        name: "series3",
        data: [33, 42, 55, 62, 74, 52, 101]
      },
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100]
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41]
      }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "area",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        colors: ["#7460EE", "#398BF7", "#E9EDF2"],
        opacity: 0.9,
        type: "solid",
        gradient: {
          shade: "dark",
          type: "horizontal",
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: []
        }
      },
      colors: ["", "", ""],
      legend: {
        show: false
      },
      markers: {
        colors: ["#7460EE", "#398BF7", "#E9EDF2"],
        strokeColors: ["#7460EE", "#398BF7", "#E9EDF2"]
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z"
        ]
      },
      tooltip: {
        theme: "dark",
        x: {
          format: "dd/MM/yy HH:mm"
        }
      }
    },
    pie: {
      pieSeries: [500, 350, 100, 50],
      pieOptions: {
        chart: {
          type: "donut",
          tooltip: {
            theme: "dark"
          }
        },
        width: "50px",
        stroke: {
          show: "false"
        },
        fill: {
          colors: ["#7460EE", "#398BF7", "#52dea5", "#E9EDF2"]
        },
        labels: ["PC", "Tablet", "Phone", "Other"],
        colors: ["#7460EE", "#398BF7", "#52dea5", "#E9EDF2"],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      }
    },
    users: {
      headers: [
        {
          value: "name",
          text: "Name"
        },
        {
          value: "priority",
          text: "Priority"
        },
        {
          value: "earnings",
          text: "Earnings"
        }
      ],
      items: [...new Array(10)].map(item => ({
        name: 123,
        priority: "low",
        earnings: "4"
      }))
    },
    comments: [
      {
        user: {
          name: "James Anderson",
          src:
            "https://wrappixel.com/demos/vuejs-admin-templates/adminpro-vuetify-admin/main/img/1.8cccc71b.jpg"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "blue lighten-4 blue--text",
          text: "Pending"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "Jenny Anderson",
          src:
            "https://wrappixel.com/demos/vuejs-admin-templates/adminpro-vuetify-admin/main/img/4.7eef6399.jpg"
        },
        greyish: true,
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "red lighten-4 red--text",
          text: "Rejected"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "Thomas Anderson",
          src:
            "https://wrappixel.com/demos/vuejs-admin-templates/adminpro-vuetify-admin/main/img/2.92fb17ec.jpg"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "yellow lighten-4 orange--text",
          text: "Pending"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "Mister Anderson",
          src:
            "https://www.ivi.ru/titr/uploads/2015/09/02/8b702b535068e94f4dcfa42bfd1b516e.jpg/1400x0"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "green lighten-4 green--text",
          text: "Approved"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "James Anderson",
          src:
            "https://wrappixel.com/demos/vuejs-admin-templates/adminpro-vuetify-admin/main/img/1.8cccc71b.jpg"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "blue lighten-4 blue--text",
          text: "Pending"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "Jenny Anderson",
          src:
            "https://wrappixel.com/demos/vuejs-admin-templates/adminpro-vuetify-admin/main/img/4.7eef6399.jpg"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "red lighten-4 red--text",
          text: "Rejected"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "Thomas Anderson",
          src:
            "https://wrappixel.com/demos/vuejs-admin-templates/adminpro-vuetify-admin/main/img/2.92fb17ec.jpg"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "yellow lighten-4 orange--text",
          text: "Pending"
        },
        date: "January 1, 2020"
      },
      {
        user: {
          name: "Mister Anderson",
          src:
            "https://www.ivi.ru/titr/uploads/2015/09/02/8b702b535068e94f4dcfa42bfd1b516e.jpg/1400x0"
        },
        text:
          "Lorem Ipsum is simply dummy text of the printing and type setting industry.",
        status: {
          class: "green lighten-4 green--text",
          text: "Approved"
        },
        date: "January 1, 2020"
      }
    ]
  })
});
